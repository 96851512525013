import React from "react";
import Mosaic from "../../Mosaic";
import styled from "styled-components";
import { T } from "../../../styles/Theme";
import Fade from "react-reveal/Fade";

const Fifth = () => {
  return (
    <FifthStyled>
      <a className="anchor" id="nosotros"></a>
      <h2 className="title-section">NOSOTROS</h2>
      <p className="title-description">
        Todos nuestros conocimientos y expertise a vuestra disposición
      </p>
      <Mosaic />

      <Fade bottom>
        <div className="textContainer">
          <p>
            Somos un equipo multidisciplinario, conformado por profesionales de distintas áreas, Ingenieros agrónomos, en computación, técnicos agrícolas y personal dedicado a la administración, finanzanzas, comunicación y marketing.
            Nos vemos siendo el mejor partner de las empresas agrícolas y agroindustriales de Centro y Sudamérica.
            Nuestro respaldo es el conocimiento y una vasta experiencia en el desarrollo e implementación de soluciones, orientadas a optimizar procesos en el control de calidad de fruta fresca de exportación.
            Conocemos las necesidades de las empresas exportadoras y, las asesoramos, asistimos y acompañamos en su transformación digital.
          </p>
          <p>
              Entendemos la realidad actual del rubro
              agrícola, sus problemáticas, carencias y
              desafíos. Sabemos que aún le falta mucho
              por avanzar, tanto en tecnología como en la
              optimización de sus procesos y recursos.
              Queremos ser parte de esta evolución,
              guiando, educando y entregando
              herramientas que faciliten el nuevo camino
              hacia la eficiencia y digitalización.
              También queremos apoyar a los
              trabajadores, a través de la capacitación y
              especialización de distintos  oficios agrícolas
              y agroindustriales.
              Es parte de nuestra misión y del trabajo
              propuesto en el presente y futuro próximo.

          </p>
        </div>
      </Fade>
    </FifthStyled>
  );
};

export default Fifth;

const FifthStyled = styled.section`
  padding-bottom: 60px;
  width: 100%;

  .title-section {
    padding-top: 75px;
  }

  a.anchor {
    display: block;
    position: relative;
    top: 0;
    visibility: hidden;
  }

  .textContainer {
    margin: auto;
    max-width: ${T.maxWidth};
    padding: 20px;
  }

  p {
    max-width: 480px;
    line-height: 2;
    font-size: 14px;
    margin: 10px auto;
  }

  .textOrange {
    color: ${T.orange};
  }

  @media (min-width: 760px) {
    .textContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;
    }
  }
`;
