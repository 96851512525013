import styled from "styled-components";
import { T } from "./Theme";

export const SlideStyled = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  position: relative;
  display: flex;
  padding: 0;
  align-items: center;

  .backgroundImage {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .imageDark {
    filter: brightness(50%);
  }

  .content {
    color: white;
    z-index: 10;
    max-width: ${T.maxWidth};
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;

    h2 {
      font-size: 50px;
      margin-bottom: 10px;
      line-height: 1;
    }

    p {
      font-size: 17px;
      margin-bottom: 40px;
    }
  }

  @media screen and (max-width: 480px) {
    height: calc(60vh - 90px);

    .backgroundImage {
      position: absolute;
      height: 50%;
      height: 100%;
      width: 100%;
      z-index: 0;
    }
  }
`;
