import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { SlideStyled } from "../../styles/Header";
import { motion } from "framer-motion";
import styled from "styled-components";
import { ButtonOrange } from "../../styles/Buttons";

const textRotationObject = {
  0: "Sistematizan",
  1: "Agilizan",
  2: "Optimizan",
};

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const Slide3 = () => {
  const [animationIndex, setAnimationIndex] = useState(1);
  const [textRotation, setTextRotation] = useState("Sistematizan");

  useEffect(() => {
    setTimeout(() => {
      animationIndex === 2
        ? setAnimationIndex(0)
        : setAnimationIndex(animationIndex + 1);

      setTextRotation(textRotationObject[animationIndex]);
    }, 2500);
  }, [animationIndex]);

  return (
    <SlideStyled>
      <TextAnimated>
        <h3>Asesoramos y entregamos herramientas que</h3>
        <motion.h3
          className="text-animated"
          key={animationIndex}
          initial="hidden"
          animate="visible"
          variants={variants}
        >
          {textRotation}
        </motion.h3>
        <h3 className="max-width-550">
          Los procesos de control y calidad de la fruta fresca de exportación
        </h3>

        <ButtonOrange className="button-header" href="#footer">
          CONTÁCTANOS
        </ButtonOrange>
      </TextAnimated>

      <StaticImage
        className="backgroundImage imageDark"
        src="../../images/swiper-header/slide3.png"
        alt={""}
      />
    </SlideStyled>
  );
};

export default Slide3;

const TextAnimated = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 20px;
  border-radius: 16px;
  width: 100%;
  z-index: 9000;
  text-align: center;
  color: white;

  .button-header {
    width: 120px;
    padding: 0 40px;
    margin: 20px auto 0 auto;
  }

  .max-width-550 {
    max-width: 550px;
    margin: auto;
  }

  & > h3 {
    font-weight: 300;
    margin: 0;
    font-size: 18px;
  }

  .text-animated {
    font-size: 44px;
    font-weight: 700;
  }

  @media (min-width: 600px) {
    & > h3 {
      font-size: 28px;
    }

    .text-animated {
      font-size: 50px;
    }
  }
`;
