import React from "react";
import Card from "./Card";
import styled from "styled-components";
import { T } from "../../../styles/Theme";
import {
  AsesoriasIcon,
  GestionIcon,
  SolucionesIcon,
} from "../../../static/icons";
import Fade from "react-reveal/Fade";

const Second = ({
  setModalSolucionesTecnologicas,
  setModalAsesoriasTecnicas,
  setModalInspeccion,
}) => {
  return (
    <SectionStyled>
      <a className="anchor" id="servicios"></a>
      <h2 className="title-section">NUESTROS SERVICIOS</h2>
      <p className="title-description">
        Optimizando procesos en el control de calidad de fruta fresca
      </p>
      <CardContainer>
        <Fade bottom>
          <Card
            icon={<SolucionesIcon />}
            title={"Soluciones Tecnológicas"}
            onClick={() => setModalSolucionesTecnologicas(true)}
          >
            Programas técnicos y complementarios.
          </Card>
        </Fade>
        <Fade bottom>
          <Card
            icon={<AsesoriasIcon />}
            title={"Asesorías Técnicas"}
            onClick={() => setModalAsesoriasTecnicas(true)}
          >
            Para la implementación de sistemas de control de calidad y/o gestión
          </Card>
        </Fade>

        <Fade bottom>
          <Card
            icon={<GestionIcon />}
            title={"Gestión control de calidad"}
            onClick={() => setModalInspeccion(true)}
          >
            Inspección de Control de Calidad en fruta fresca de exportación.
          </Card>
        </Fade>
      </CardContainer>

      <Fade bottom>
        <div className="videoContainer">
            <iframe
                src="https://inglobochile.com/video/inglobochile.mp4"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            >
                {}
            </iframe>
        </div>
      </Fade>
    </SectionStyled>
  );
};

export default Second;

const SectionStyled = styled.section`
  max-width: ${T.maxWidth};
  margin: 15px auto;
  padding: 25px 0 35px 0;

  a.anchor {
    display: block;
    position: relative;
    top: -85px;
    visibility: hidden;
  }

  .videoContainer {
    width: 90%;
    max-width: ${T.maxWidth};
    margin: 20px auto 0;

    iframe {
      width: 100%;
      height: 400px;
    }
  }

  @media (min-width: 760px) {
    padding: 75px 0 60px 0;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 760px) {
    flex-direction: row;
  }
`;
