import * as React from "react";
import SwiperComponent from "../components/HeaderCarousel";
import Second from "../components/Sections/Services";
import DivisionOne from "../components/Divisions/DivisionOne";
import Third from "../components/Sections/Third";
import Fourth from "../components/Sections/Fourth";
import Clients from "../components/Divisions/Clients";
import Fifth from "../components/Sections/Nosotros";
import Testimonials from "../components/Testimonials";

import Contact from "../components/Sections/Contact";
import DivisionTwo from "../components/Divisions/DivisionTwo";
import AsesoriasTecnicas from "../components/Modal/AsesoriasTecnicas";
import { useState } from "react";
import Inspeccion from "../components/Modal/Inspeccion";
import SoftwareComplementario from "../components/Modal/SoftwareComplementario";
import SolucionesTecnologicas from "../components/Modal/SolucionesTecnologicas";
import SoftwareTecnicos from "../components/Modal/SoftwareTecnicos";
import { Layout } from "../components/Layout";

const IndexPage = () => {
  const [modalSolucionesTecnologicas, setModalSolucionesTecnologicas] =
    useState(false);
  const [modalAsesoriasTecnicas, setModalAsesoriasTecnicas] = useState(false);
  const [modalInspeccion, setModalInspeccion] = useState(false);

  const [modalSoftwareComplementario, setModalSoftwareComplementario] =
    useState(false);
  const [modalSoftwareTecnicos, setModalSoftwareTecnicos] = useState(false);

  return (
    <>
      <Layout>
        <SwiperComponent />
        <Second
          setModalSolucionesTecnologicas={setModalSolucionesTecnologicas}
          setModalAsesoriasTecnicas={setModalAsesoriasTecnicas}
          setModalInspeccion={setModalInspeccion}
        />
        <DivisionOne />
        <Third />
        <DivisionTwo />
        <Fourth />
        <Clients />
        <Fifth />
        <Testimonials />

        <Contact />
      </Layout>

      {modalSolucionesTecnologicas && (
        <SolucionesTecnologicas
          setModalSolucionesTecnologicas={setModalSolucionesTecnologicas}
          setModalSoftwareComplementario={setModalSoftwareComplementario}
          setModalSoftwareTecnicos={setModalSoftwareTecnicos}
        />
      )}

      {modalInspeccion && (
        <Inspeccion setModalInspeccion={setModalInspeccion} />
      )}

      {modalAsesoriasTecnicas && (
        <AsesoriasTecnicas
          setModalAsesoriasTecnicas={setModalAsesoriasTecnicas}
        />
      )}

      {modalSoftwareComplementario && (
        <SoftwareComplementario
          setModalSoftwareComplementario={setModalSoftwareComplementario}
        />
      )}

      {modalSoftwareTecnicos && (
        <SoftwareTecnicos setModalSoftwareTecnicos={setModalSoftwareTecnicos} />
      )}
    </>
  );
};

export default IndexPage;
