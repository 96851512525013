import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { T } from "../../styles/Theme";
import { Logo } from "../../static/logo";

const Mosaic = () => {
  return (
    <MosaicStyled>
      <div className="mosaic-container">
        {/*<div className="mosaic-image mosaic-logo">*/}
        {/*  <StaticImage src="../../images/logoW.png" alt="" />*/}
        {/*</div>*/}
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/1.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Lucy Zapata</h5>
            <p>Directora de proyectos y Cofundadora</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/2.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Bernardo Torres</h5>
            <p>Líder de operaciones y desarrollo</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/3.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Paola Cerna</h5>
            <p>Encargada de Calidad y Capacitación</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/4.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Paula Tobar</h5>
            <p>Encargada de Desarrollo Fullstack</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/5.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Náyada González</h5>
            <p>Encargada de Reclutamiento y selección de personal</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/Anibal.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Anibal Vásquez</h5>
            <p>Coordinador de Proyectos</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/7.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Paulina Cornejo</h5>
            <p>Encargada de Administración</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/9.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Carla Silva</h5>
            <p>Asistente Administrativa</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/10.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Jorge Inostroza</h5>
            <p>Supervisor Técnico</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/11.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Estefanía Pino</h5>
            <p>Supervisora Tecnológica</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/12.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Camila Jara</h5>
            <p>Supervisora Tecnológica</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/13.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Ernesto Reyes</h5>
            <p>Supervisor Técnico</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/14.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Juan Reinoso</h5>
            <p>Supervisor General</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/15.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Clarita Cabezas</h5>
            <p>Encargada del Cuidado de Nuestro Espacio</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/16.jpg" alt=""/>
          <div className="hoverInfo">
            <h5>Maximiliano Guerrero</h5>
            <p>Supervisor Técnico</p>
          </div>
        </div>
        <div className="mosaic-image">
          <StaticImage src="../../images/Team/17.jpg" alt="" />
          <div className="hoverInfo">
            <h5>Rita Fernández</h5>
            <p>Supervisora Técnica</p>
          </div>
        </div>

        {/*<div className="mosaic-image mosaic-logo mosaic-logo-orange">*/}
        {/*  <StaticImage src="../../images/logoW2.png" alt="" />*/}
        {/*</div>*/}
      </div>
    </MosaicStyled>
  );
};

export default Mosaic;

const MosaicStyled = styled.div`
  padding: 50px 20px 0px 20px;
  max-width: ${T.maxWidth};
  width: 80%;
  margin: auto;

  .hoverInfo {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: rgba(130, 166, 142, 0.8);
    color: white;
    transition: all 0.4s;
    text-align: center;

    h5 {
      font-size: 11px;
      margin: 0;
    }

    p {
      margin: 0;
      font-size: 10px;
      line-height: 1.4;
    }
  }

  .mosaic-image {
    position: relative;
    z-index: 10;
    &:hover .hoverInfo {
      display: flex;
    }
  }

  .mosaic-logo {
    display: none;
    background-color: ${T.gray};
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .mosaic-logo-orange {
    background-color: ${T.orange};
  }

  .mosaic-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  @media (min-width: 760px) {
    padding: 50px 20px 60px 20px;

    .hoverInfo {
      h5 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }

    .mosaic-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }

    .mosaic-logo {
      display: flex;
    }
  }
`;
