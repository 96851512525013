import React from "react";
import styled from "styled-components";
import { Input } from "../../../styles/Inputs";
import { ButtonOrange } from "../../../styles/Buttons";
import { T } from "../../../styles/Theme";

const Contact = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");


  const handleClick = async(e) => {
    e.preventDefault()
    const body = {
        name,
        email,
        phone,
        company,
        country,
        state
    }
    if (name === "" || email === "" || phone === "" || company === "" || country === "") {
      alert("Por favor, complete todos los campos")
    } else {
    await fetch('/api/mail-contacto', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
    setCompany("")
    setCountry("")
    setEmail("")
    setName("")
    setPhone("")
    setState("")
  }
  }

  return (
    <ContactStyled>
        <a className="anchor" id="contacto"></a>
        <h2 className="title-section">CONTACTO</h2>
        <p className="title-description">
            Para conocer mejor nuestros servicios agenda una reunión virtual:
        </p>

        <div className="grid-container">
            <div className="formContainer">
                <form action="">
                    <div className="inputContainer">
                        <Input placeholder={"Nombre"} onChange={(e) => setName(e.target.value)} required/>
                        <Input placeholder={"Empresa"} onChange={(e) => setCompany(e.target.value)} required/>
                        <Input type="email" placeholder={"Correo Electrónico"} onChange={(e) => setEmail(e.target.value)} required/>
                        <Input placeholder={"Teléfono"} onChange={(e) => setPhone(e.target.value)} required/>
                        <Input placeholder={"Región"} onChange={(e) => setState(e.target.value)}/>
                        <Input placeholder={"País"} onChange={(e) => setCountry(e.target.value)} required/>
                    </div>
                    <ButtonOrange 
                      onClick={e => handleClick(e)}
                    >ENVIAR</ButtonOrange>
                </form>
            </div>

            <MapStyled className="gmap_canvas">
                <iframe id="gmap_canvas"
                        src="https://maps.google.com/maps?q=quebrada%20de%20cerrillos%2055&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                </iframe>
            </MapStyled>
        </div>
    </ContactStyled>
  );
};

export default Contact;

const MapStyled = styled.div`
  width: 100%;
  height: 200px;

  iframe {
    padding: 20px 0;
    width: 100%;
    height: 100%;
  }
`;

const ContactStyled = styled.section`
  padding: 60px 20px 90px 20px;
  max-width: ${T.maxWidth};
  margin: auto;

  a.anchor {
    display: block;
    position: relative;
    top: 0;
    visibility: hidden;
  }

  .inputContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 15px;
  }

  .grid-container {
    @media (min-width: 760px) {
      padding: 0 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      grid-gap: 30px;

      .formContainer {
        align-self: center;
        width: 100%;
      }
      .gmap_canvas {
        align-self: center;
        //width: 330px;
        height: 400px;
      }
    }
  }
`;
