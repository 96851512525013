import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import {SlideStyled} from "../../styles/Header";

const Slide2 = () => {
    return (
        <SlideStyled>
            <StaticImage
                className="backgroundImage"
                src="../../images/swiper-header/slide2.png"
                alt={""}
            />
        </SlideStyled>
    );
};

export default Slide2;