import React from 'react';
import Modal from "../Modal";
import CircleGraph from "./CircleInfo";
import styled from "styled-components";
import {T} from "../../../styles/Theme"

const data = [
    "Control Consolidación de Contenedores / Camiones Refrigerados",
    "Control destino",
    "Control procesos",
    "Control Recepción",
    "Control Producto terminado",
    "Control Mercado Interno",
    "Control Cajas Testigo"
]


const SoftwareTecnicos = ({setModalSoftwareTecnicos}) => {
    return (
        <Modal handleClose={() => setModalSoftwareTecnicos(false)}>
            <Container>
                {/*<ModalTitle>*/}
                {/*    Sofware técnicos*/}
                {/*</ModalTitle>*/}

                <p className={"description"}>
                    Arriendo de softwares técnicos que permiten realizar el control de calidad en diferentes etapas del proceso de
                    exportación de fruta fresca. Logrando verificar el cumplimiento de normativas propias de la empresa o de sus clientes.
                </p>

                <div className="circleContainer">
                    <CircleGraph/>
                </div>

                <div className="subContainer">
                    <ul>
                        {
                            data.map((el) => (
                                <li key={el} >
                                    <i className="fas fa-check-circle"></i>
                                    <p>
                                        {el}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>

                    <p className={"text-center"}>
                        <i>
                            De puntos críticos en las diferentes etapas del ciclo de recolección, calidad, almacenamientos y despacho de la fruta fresca de exportación. Mantiene la
                            trazabilidad del producto terminado.
                        </i>
                    </p>
                </div>
            </Container>
        </Modal>
    );
};

export default SoftwareTecnicos;

const Container = styled.div`
  padding: 10px 30px 30px 30px;
  
  .circleContainer {
    display: none;
    @media (min-width: 568px) {
      display: block;
    }
  }
  
  .description {
    font-size: 15px;
    line-height: 1.8;
    text-align: center;
    max-width: 600px;
  }

  .subContainer {
    ul {
      list-style: none;
      padding: 0;

      & > li {
        display: flex;
        align-items: baseline;
        margin: 10px 0;

        i {
          padding-right: 5px;
          color: ${T.orange};
        }

        p {
          margin: 0;
          font-size: 12px;
        }
      }
    }
    
    .text-center {
      font-size: 12px;
      line-height: 1.8;
    }

    @media (min-width: 568px) {
      display: none;
    }
  }
`;

