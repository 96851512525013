import React from 'react';
import styled from "styled-components";
import {T} from "../../../styles/Theme"

const Card = ({icon, title, onClick, children}) => {
    return (
        <CardStyled onClick={onClick}>
            {icon}
            <div className="content">
                <h3>{title.toUpperCase()}</h3>
                <p>{children}</p>
            </div>
        </CardStyled>
    );
};

export default Card;

const CardStyled = styled.div`
  border-radius: 20px;
  min-width: 290px;
  max-width: 298px;
  min-height: 350px;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 26%);
  margin: 25px;
  border: 1px solid transparent;
  cursor: pointer;
  
  svg {
    margin: 55px 0 0 40px;
  }
  
  .content {
    padding: 20px 40px;
    
    h3 {
      font-size: 25px;
      margin: 0;
      font-weight: 500;
      line-height: 1.2;
    }
    
    p {
      font-size: 14px;
      margin-top: 18px;
    }
  }
  
  &:hover {
    border: 1px solid ${T.orange};
  }
`;