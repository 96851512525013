import React from 'react';
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Autoplay} from "swiper";

import "swiper/css";
import {
    Client1,
    Client10, Client11, Client12,
    Client2,
    Client3,
    Client4,
    Client5,
    Client6,
    Client7,
    Client8,
    Client9
} from "../../static/clients";

SwiperCore.use([Autoplay]);

const Clients = () => {
    return (
        <DivisionThreeStyled>
            <h2 className="title-section">NUESTROS CLIENTES</h2>
            <p className="title-description">
                Algunas de las empresas que han confiado en nuestro trabajo
            </p>

            <SwiperStyled
                slidesPerView={2}
                spaceBetween={10}
                loop
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false
                }}
                breakpoints={{
                    480: {
                        slidesPerView: 3,
                    },
                    720: {
                        slidesPerView: 4,
                    },
                    1020: {
                        slidesPerView: 5,
                    }
                }}
            >
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client1/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client2/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client3/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client4/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client5/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client6/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client7/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client8/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client9/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client10/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client11/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="logoContainer">
                        <Client12/>
                    </div>
                </SwiperSlide>
            </SwiperStyled>
        </DivisionThreeStyled>
    );
};

export default Clients;

const SwiperStyled = styled(Swiper)`
  height: 100%;
  .logoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const DivisionThreeStyled = styled.div`
  .title-description {
    text-align: center;
    margin: auto;
    margin-bottom: 30px;
  }
  //height: 170px;
  padding: 30px 0;
  width: 100%;
  background-color: #F8F8F8;
`;