import React from 'react';
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
// import {T} from "../../../styles/Theme"
import Fade from 'react-reveal/Fade';

const Third = () => {
    return (
        <ThirdStyled>
            <Fade bottom>
                <Block>
                    <StaticImage
                        className="oranges-image"
                        src="../../../images/oranges.png"
                        alt=""
                    />
                    <p>
                        Conocimiento de
                        <strong> requisitos y necesidades </strong>
                        de los principales
                        compradores de fruta fresca.
                    </p>
                </Block>
            </Fade>

            <Fade bottom>
                <Block>
                    <p>
                        Expertise en los
                        <strong> tratamientos y procesos </strong>
                        a realizar para
                        exportar fruta fresca.
                    </p>
                    <StaticImage
                        className=""
                        src="../../../images/containersImage.png"
                        alt=""
                    />
                </Block>
            </Fade>

        </ThirdStyled>
    );
};

export default Third;

const ThirdStyled = styled.section`
  margin: 0 auto;
  padding: 30px 0;
  
  
  @media (min-width: 550px) {
    padding: 100px 0;
  }

  @media (min-width: 800px) {
    .oranges-image {
      max-width: 450px;
      margin-right: 40px;
    }
  }

`;

const Block = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  
  p {
    font-size: 18px;
    max-width: 350px;
  }

  @media (min-width: 600px) {
    flex-direction: row;

    p {
      padding: 0 40px;
    }
  }

  @media (min-width: 750px) {
    p {
      font-size: 24px;
    }
  }
`;