import React from 'react';
import Modal from "../Modal";
import styled from "styled-components";
import {T} from "../../../styles/Theme"
import {StaticImage} from "gatsby-plugin-image";

const Inspeccion = ({setModalInspeccion}) => {

    const data = [
        "Auditorias Labores en Huerto",
        "Recepción de Fruta a Granel",
        "Control de Puntos Críticos en procesos",
        "Producto Terminado",
        "Producto Mercado Interno",
        "Control de Despachos:"
    ]

    return (
        <Modal handleClose={() => setModalInspeccion(false)}>
            {/*<ModalTitle>*/}
                {/*<h5>Inspección de Control de Calidad</h5>*/}
                {/*<p>Fruta fresca de exportación</p>*/}
            {/*</ModalTitle>*/}
            <Content>
                {/*<h6>Descripción del servicio</h6>*/}
                <p>
                    Inspectores realizan la verificación del cumplimiento de normativas, en diferentes etapas del proceso de fruta fresca
                    de exportación, entre ellos:
                </p>
                <div className="subContainer">
                    <ul>
                        {
                            data.map((el) => {
                                return (
                                    <li key={el}>
                                        <i className="fas fa-check-circle"></i>
                                        <p>
                                            {el}
                                        </p>
                                    </li>
                                )
                            })
                        }
                        <li>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>- Consolidación de Contenedores</p>
                        </li>
                        <li>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>- Inspección de Camiones Refrigerados</p>
                        </li>
                    </ul>

                    <StaticImage className={"inspeccion-image"} src={"../../../static/modal/gestioncontrolcalidad/foto_9.png"} alt=""/>
                </div>
            </Content>
        </Modal>
    );
};

export default Inspeccion;

const Content = styled.div`
  padding: 30px;
  overflow-y: auto;
  width: 90%;
  
  .inspeccion-image {
    display: none;
    border-radius: 32px;
    max-width: 380px;
  }
  
  h6 {
    font-size: 25px;
    margin: 0;
    font-weight: 400;
  }

  p {
    font-size: 14px;
  }

  ul {
    list-style: none;
    padding: 0;

    & > li {
      display: flex;
      align-items: baseline;
      margin: 10px 0;

      i {
        padding-right: 5px;
        color: ${T.orange};
      }
      
      p {
        margin: 0;
      }
    }
  }
  
  @media (min-width: 768px) {
    width: 80%;
    .inspeccion-image {
      display: block;
    }
    
    .subContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;

        .gatsby-image-wrapper {
          max-height: 230px;
          align-self: center;
        }
    }
  }
`;
