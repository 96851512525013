import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import Slide1 from "./Slide1";

import { Pagination, EffectFade } from "swiper";
import Slide2 from "./Slide2";
import Slide3 from "./Slide3";

const SwiperComponent = () => {
  return (
    <ScreenStyled>
      <a className="anchor" id="home"></a>
      <SwiperStyled
        slidesPerView={1}
        pagination={true}
        modules={[Pagination, EffectFade]}
        effect="fade"
        loop
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <Slide3 />
        </SwiperSlide>
        <SwiperSlide data-swiper-autoplay="2000">
          <Slide2 />
        </SwiperSlide>
        <SwiperSlide data-swiper-autoplay="2000">
          <Slide1 />
        </SwiperSlide>
      </SwiperStyled>
    </ScreenStyled>
  );
};

export default SwiperComponent;

const ScreenStyled = styled.div`
  position: relative;
`;

const SwiperStyled = styled(Swiper)`
  margin-top: 130px;
  .swiper-pagination-bullets {
    display: none;
    bottom: 40px;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border: 3px solid white;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: white;
    width: 6px;
    height: 6px;
  }

  @media screen and (min-width: 480px) {
    .swiper-pagination-bullets {
      display: inherit;
    }
  }
`;
