import React from 'react';
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import {Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

const dataSwiper = [
    'Análisis de datos en forma rápida, mediante gráficos y muestras fotográficas de resultados',
    'Obtención de trazabilidad de procesos y productos en forma instantánea.',
    'Aplicación de una o más normativas al mismo tiempo.',
    'Ingreso de datos en forma online u offline, a través de Tablet o vía Web.'
]

const DivisionTwo = () => {
    return (
        <DivisionStyled>
            <div className="content">
                <h4>Nos adaptamos <strong>a cada cliente.</strong></h4>
                <SwiperStyled
                    slidesPerView={1}
                    pagination={true}
                    modules={[Pagination]}
                    loop
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                >
                    {
                        dataSwiper.map((item) => (
                            <SwiperSlide key={item}>
                                <p>{item}</p>
                            </SwiperSlide>
                        ))
                    }
                </SwiperStyled>
            </div>

            <StaticImage
                className="backgroundImage"
                src="../../images/division-2.jpg"
                alt={""}
            />
        </DivisionStyled>
    );
};

export default DivisionTwo;

const SwiperStyled = styled(Swiper)`
  color: white;
  text-align: center;
  height: 120px;

  .swiper-pagination-bullets {
    bottom: -2px !important;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: transparent;
    border: 3px solid white;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: white;
    width: 6px;
    height: 6px;
  }

  @media (min-width: 550px) {
    height: 130px;
  }
`;

const DivisionStyled = styled.div`
  width: 100%;
  height: 380px;
  position: relative;
  display: flex;
  align-items: center;

  .backgroundImage {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  .content {
    text-align: center;
    color: white;
    z-index: 10;
    width: 100%;
    margin: 0 auto;
    
    h4 {
      margin: 0;
      font-size: 20px;
    }
  }
  
  @media (min-width: 550px) {
   .content {
     h4 {
       font-size: 40px;
     }
   }
    
    p {
      font-size: 20px;
    }
  }
`;