import React from 'react';
import Modal from "../Modal";
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";

const SoftwareComplementario = ({setModalSoftwareComplementario}) => {
    return (
        <Modal handleClose={() => setModalSoftwareComplementario(false)}>
            <Content>
                <p className={"description"}>
                    Arriendo de softwares complementarios, que ayudan en distintos
                    procesos de administración, gestión y capacitación.
                </p>

                <div className="block">
                    <StaticImage src={"../../../static/modal/softwarecomplementario/3.png"} alt="software complementario"/>

                    <div className="text">
                        <h6>Plataforma AGROINM</h6>

                        <p>
                            Permite capacitar a los trabajadores en diferentes
                            cursos gratuitos y/o internos de las  empresas agrícolas
                            y agroindustriales. Conecta personal calificado con
                            empresarios del rubro.
                        </p>
                    </div>
                </div>

                <div className="block">
                    <StaticImage src={"../../../images/modal/Escena_Herramientas.jpg"} alt="software complementario"/>

                    <div className="text">
                        <h6>Control Instrumental</h6>

                        <p>
                            Mantiene el control de instrumentos entregados a los
                            usuarios. Valoriza y mantiene actualizado el stock total.
                        </p>
                    </div>
                </div>

                <div className="block">
                    <StaticImage src={"../../../images/modal/Escena_Calculadora.jpg"} alt="software complementario"/>

                    <div className="text">
                        <h6>Control de Traslados</h6>

                        <p>
                            Sistema de rendiciones de gastos, detalle de pagos.
                        </p>
                    </div>
                </div>
            </Content>
        </Modal>
    );
};

export default SoftwareComplementario;

const Content = styled.div`
  padding: 10px 30px 30px 30px;
  width: 100%;
  overflow-y: auto;

  h6 {
    font-size: 24px;
    margin: 10px 0;
    font-weight: 400;
  }

  p {
    font-size: 14px;
    line-height: 1.8;
    margin: 0 0 10px 0;
  }
  
  

  .block {
    padding: 15px;
    .text {
      //padding: 10px;
      max-width: 400px;
    }
  }

  .gatsby-image-wrapper {
    display: none;
    max-width: 300px;
    max-height: 200px;
    width: 100%;
    border-radius: 32px;
    align-self: center;
    justify-self: flex-end;
    margin-right: 25px;
  }

  .description {
    font-size: 15px;
    line-height: 1.8;
    text-align: center;
    max-width: 600px;
    margin: auto;
    margin-bottom: 30px;
    padding: 10px;
  }

  @media (min-width: 768px) {
    .block {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .gatsby-image-wrapper {
      display: block;
    }
  }
`;

