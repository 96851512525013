import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { SlideStyled } from "../../styles/Header";

const Slide1 = () => {
  return (
    <SlideStyled>
      <StaticImage
        className="backgroundImage"
        src="../../images/swiper-header/slide1.png"
        alt={""}
      />
    </SlideStyled>
  );
};

export default Slide1;
