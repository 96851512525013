import React from 'react';
import Modal from "../Modal";
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";
import {ButtonOrange} from "../../../styles/Buttons";

const SolucionesTecnologicas = ({
                                    setModalSolucionesTecnologicas,
                                    setModalSoftwareComplementario,
                                    setModalSoftwareTecnicos
}) => {

    const handleClickModal = (callback) => {
        callback(true)
    }

    return (
        <ModalStyled handleClose={() => setModalSolucionesTecnologicas(false)}>

            <Content>
                <p className={"description"}>
                    Puedes elegir los módulos que necesites. Todos nuestros programas se entregan en modalidad de arriendo e incluyen apoyo técnico constante tanto en la implementación como en su uso.
                </p>

                <div className="content-container">
                    <div className="block">
                        <StaticImage src={"../../../images/modal/asesorias-tecnicas/foto_Sistemas.jpg"} alt="software tecnicos" />
                        <ButtonOrange className="modal--btn-orange" onClick={() => handleClickModal(setModalSoftwareTecnicos)}
                        >PROGRAMAS TÉCNICOS</ButtonOrange>
                    </div>

                    <div className="block">
                        <StaticImage src={"../../../images/modal/soluciones-tecnologicas/foto2.png"} alt="software complementarios" />
                        <ButtonOrange className="modal--btn-orange" onClick={() => handleClickModal(setModalSoftwareComplementario)}
                        >PROGRAMAS COMPLEMENTARIOS</ButtonOrange>
                    </div>
                </div>
            </Content>
        </ModalStyled>
    );
};

export default SolucionesTecnologicas;

const ModalStyled = styled(Modal)`
  
`;

const Content = styled.div`
  .description {
    font-size: 15px;
    line-height: 1.8;
    text-align: center;
    max-width: 600px;
    margin: auto;
    padding: 10px;
  }
  
  width: 100%;
  max-width: 700px;
  overflow-y: auto;
  
  .modal--btn-orange {
    padding: 2px 10px;
    background-color: #F19447;
    font-weight: 600;
    border-radius: 16px;
    margin-top: 10px;
  }
  
  h6 {
    font-size: 24px;
    margin: 10px 0;
    font-weight: 400;
  }
  
  .gatsby-image-wrapper {
    max-width: 350px;
    max-height: 200px;
    width: 100%;
    border-radius: 6px;
    justify-self: center;
    margin-bottom: 10px;
  }
  
  .block {
    padding: 20px;
    //width: 100%;
    max-width: 345px;
    margin: auto;
  }
  
  
  @media (min-width: 768px) {
    .content-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 40px;
    }
  }
`;

