import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {T} from "../../../styles/Theme"

const dataHover = {
    0: "Control Consolidación de Contenedores / Camiones Refrigerados: verificación de condiciones internas y externas del medio de transporte, consolidación de carga y medidores durante el viaje.",
    1: "Control Destino: inspección de calidad y condición de la fruta a su llegada a los mercados finales.",
    2: "Control Procesos: de puntos críticos en las diferentes etapas del ciclo de exportación de la fruta fresca. Mantiene la trazabilidad del producto terminado.",
    3: "Control Recepción: inspección de calidad y condición de materia prima. Determina el potencial de exportación de la fruta.",
    4: "Control Producto Terminado: inspección final. Verifica el cumplimiento de las normativas que se exigen al exportar.",
    5: "Control Mercado Interno: inspección de la fruta que no cumple con los requisitos para ser exportada. Detalla las causales y la presencia de cantidad exportable.",
    6: "Control Cajas Testigo: registro de condiciones de contra muestras de embarques. Entrega un análisis comparativo de la fruta en su salida y llegada a destino."
}

const initialText = "De puntos críticos en las diferentes etapas del ciclo de recolección,\n" +
    "calidad, almacenamientos y despacho de la fruta fresca de exportación. Mantiene la\n" +
    "trazabilidad del producto terminado."


const CircleGraph = () => {
    const [centerText, setCenterText] = useState(initialText);
    const [centerBackground, setCenterBackground] = useState("#64766A");

    const handleOut = () => {
        setCenterText(initialText)
        setCenterBackground("#64766A")
    }

    const handleOver = (index) => {
        setCenterText(dataHover[index])
        setCenterBackground("#D88219")
    }

    useEffect(() => {
    }, [centerText]);


    return (
        <CircleStyled centerbackground={centerBackground}>
            <ul className="circle">
                <li onMouseOver={() => handleOver(1)} onMouseLeave={handleOut}>
                    <div className="text">
                    </div>
                </li>
                <li onMouseOver={() => handleOver(2)} onMouseLeave={handleOut}>
                    <div className="text"></div>
                </li>
                <li onMouseOver={() => handleOver(3)} onMouseLeave={handleOut}>
                    <div className="text"></div>
                </li>
                <li onMouseOver={() => handleOver(4)} onMouseLeave={handleOut}>
                    <div className="text"></div>
                </li>
                <li onMouseOver={() => handleOver(5)} onMouseLeave={handleOut}>
                    <div className="text"></div>
                </li>
                <li onMouseOver={() => handleOver(6)} onMouseLeave={handleOut}>
                    <div className="text"></div>
                </li>
                <li onMouseOver={() => handleOver(0)} onMouseLeave={handleOut}>
                    <div className="text"></div>
                </li>

                <p className={"text1"}>Control <br/>Consolidación de Contenedores /
                    Camiones Refrigerados</p>
                <p className={"text2"}>Control destino</p>
                <p className={"text3"}>Control <br/>Procesos</p>
                <p className={"text4"}>Control <br/>Recepción</p>
                <p className={"text5"}>Control <br/>Producto terminado</p>
                <p className={"text6"}>Control <br/>mercado interno</p>
                <p className={"text7"}>Control <br/>cajas testigo</p>

                <p className={"text-center"}>
                    {centerText}
                </p>

                <div className="centerCircle"></div>
            </ul>
        </CircleStyled>
    );
};

export default CircleGraph;

const CircleStyled = styled.div`
  margin-top: -110px;
  transform: scale(0.6);
  
  @media (min-height: 555px) {
    transform: scale(0.65);
    margin-top: -90px;
  }

  @media (min-height: 580px) {
    transform: scale(0.7);
    margin-top: -80px;
  }

  @media (min-height: 625px) {
    transform: scale(0.8);
    margin-top: -60px;
  }

  @media (min-height: 655px) {
    transform: scale(0.85);
    margin-top: -40px;
  }

  @media (min-height: 710px) {
    transform: scale(0.9);
    margin-top: -0px;
  }
  
  p {
    z-index: 1000;
    color: white;
    position: absolute;
    text-align: center;
    pointer-events: none;
    font-weight: 600;
    font-size: 14px;
  }
  
  //TEXTOS
  .text1 {
    top: 17px;
    left: 100px;
    max-width: 150px;
    font-size: 13px;
  }

  .text2 {
    top: 53px;
    right: 120px;
  }

  .text3 {
    top: 183px;
    right: 30px;
  }
  
  .text4 {
    top: 340px;
    right: 65px;
  }
  
  .text5 {
    bottom: 26px;
    right: 184px;
  }
  
  .text6 {
    top: 340px;
    left: 52px;
  }
  
  .text7 {
    top: 183px;
    left: 15px;
  }
  
  .text-center {
    max-width: 200px;
    top: 160px;
    left: 156px;
    line-height: 1.8;
    font-weight: 400;
  }
  
  // ------------------------
  .circle {
    position: relative;
    padding: 0;
    margin: 1em auto;
    width: 32em;
    height: 32em;
    border-radius: 50%;
    list-style: none;
    overflow: hidden;
  }
  
  .centerCircle {
    width: 270px;
    height: 270px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 16px;
    bottom: 0;
    background-color: ${(props) => props.centerbackground};
    border: 4px solid white;
  }

  li {
    overflow: hidden;
    position: absolute;
    top: -50%;
    right: 0;
    width: 50%;
    height: 100%;
    transform-origin: 0% 100%;
  }

  .text {
    position: absolute;
    left: -100%;
    width: 207%;
    height: 135%;
    text-align: center;
    background-color: ${T.gray};
    color: white;
    -webkit-transform: skewY(38.58deg) rotate(25.21deg);
    -ms-transform: skewY(38.58deg) rotate(25.21deg);
    transform: skewY(38.58deg) rotate(25.21deg);
    padding-top: 300px;
    
    &:hover {
      background-color: ${T.orange};
    }
  }

  li:first-child {
    -webkit-transform: rotate(0deg) skewY(-40deg);
    -ms-transform: rotate(0deg) skewY(-40deg);
    transform: rotate(0deg) skewY(-40deg);
  }

  li:nth-child(2) {
    -webkit-transform: rotate(51.42deg) skewY(-40deg);
    -ms-transform: rotate(51.42deg) skewY(-40deg);
    transform: rotate(51.42deg) skewY(-40deg);
  }

  li:nth-child(3) {
    -webkit-transform: rotate(102.84deg) skewY(-40deg);
    -ms-transform: rotate(102.84deg) skewY(-40deg);
    transform: rotate(102.84deg) skewY(-40deg);
  }

  li:nth-child(4) {
    -webkit-transform: rotate(154.26deg) skewY(-40deg);
    -ms-transform: rotate(154.26deg) skewY(-40deg);
    transform: rotate(154.26deg) skewY(-40deg);
  }

  li:nth-child(5) {
    -webkit-transform: rotate(205.68deg) skewY(-40deg);
    -ms-transform: rotate(205.68deg) skewY(-40deg);
    transform: rotate(205.68deg) skewY(-40deg);
  }

  li:nth-child(6) {
    -webkit-transform: rotate(257.1deg) skewY(-40deg);
    -ms-transform: rotate(257.1deg) skewY(-40deg);
    transform: rotate(257.1deg) skewY(-40deg);
  }

  li:nth-child(7) {
    -webkit-transform: rotate(308.52deg) skewY(-40deg);
    -ms-transform: rotate(308.52deg) skewY(-40deg);
    transform: rotate(308.52deg) skewY(-40deg);
  }
`;