import React from 'react';
import Modal from "../Modal";
import styled from "styled-components";
import {StaticImage} from "gatsby-plugin-image";

const AsesoriasTecnicas = ({setModalAsesoriasTecnicas}) => {
    return (
        <Modal handleClose={() => setModalAsesoriasTecnicas(false)}>
            {/*<ModalTitle>*/}
            {/*    Asesorías técnicas para implementación de*/}
            {/*    sistemas de control de calidad y/o de sistemas de gestión*/}
            {/*</ModalTitle>*/}

            <Content>
                <div className="block">
                    <StaticImage src={"../../../static/modal/asesoriastecnicas/Testigo.png"} alt="asesorias tecnicas"/>

                    <div className="text">
                        <h6>Asesoría en Sistemas de Control de Calidad</h6>

                        <p>
                            Apoyamos a los clientes en la definición de sus procesos de control de calidad y en la elaboración de normativas para cumplir con los requisitos de los principales compradores de fruta fresca en el mundo.
                        </p>
                    </div>
                </div>

                <div className="block">
                    <StaticImage src={"../../../static/modal/asesoriastecnicas/Escena4_Lupa.png"} alt="asesorias tecnicas"/>

                    <div className="text">
                        <h6>Auditorías de Sistemas de Control de Calidad</h6>

                        <p>
                            Auditorias de puntos críticos en los procesos y sistemas de control de calidad de fruta fresca. Se incluyen auditorias del trabajo de inspectores y/o monitores de calidad.
                        </p>
                    </div>
                </div>

                <div className="block">
                    <StaticImage src={"../../../static/modal/asesoriastecnicas/Escena5_Cajota.png"} alt="asesorias tecnicas"/>

                    <div className="text">
                        <h6>Formación de Inspectores en Control de Calidad</h6>

                        <p>
                            Programa para formar inspectores en control de calidad de fruta fresca. Incluye capacitación a trabajadores para el desarrollo de competencias y la supervisión en terreno del personal.
                        </p>
                    </div>
                </div>

                <div className="block">
                    <StaticImage src={"../../../static/modal/asesoriastecnicas/Normativa.png"} alt="asesorias tecnicas"/>

                    <div className="text">
                        <h6>Asesoría y Auditoría de Buenas Prácticas Agrícolas</h6>

                        <p>
                            Apoyo en la implementación de Protocolos de Buenas Prácticas Agrícolas, Ambientales y Laborales, para dar cumplimiento a requisitos de la legislación nacional y de los principales compradores de fruta fresca. Auditorias de Protocolos implementados en las empresas.
                        </p>
                    </div>
                </div>
            </Content>
        </Modal>
    );
};

export default AsesoriasTecnicas;

const Content = styled.div`
  padding: 10px 30px 30px 30px;
  
  width: 100%;
  overflow-y: scroll;
  
  h6 {
    font-size: 24px;
    margin: 10px 0;
    font-weight: 700;
  }
  
  p {
    font-size: 14px;
    line-height: 1.8;
    margin: 0 0 10px 0;
  }
  
  .block {
    padding: 15px;
    .text {
      //padding: 10px;
      max-width: 400px;
    }
  }
  
  .gatsby-image-wrapper {
    display: none;
    max-width: 300px;
    max-height: 200px;
    width: 100%;
    border-radius: 32px;
    justify-self: center;
    align-self: center;
  }
  
  @media (min-width: 768px) {
    .block {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .gatsby-image-wrapper {
      display: block;
    }
  }
`;
