import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Backdrop from "./Backdrop";

const dropIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const Modal = ({ handleClose, children }) => {
  return (
    <Backdrop>
      <ModalStyled
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
      >
        <CloseIcon className="fas fa-times" onClick={handleClose}></CloseIcon>
        {children}
      </ModalStyled>
    </Backdrop>
  );
};

export default Modal;

const ModalStyled = styled(motion.div)`
  position: relative;
  width: clamp(50%, 1000px, 90%);
  max-width: 1000px;
  overflow: hidden;
  margin: auto;
  padding-top: 1rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-height: calc(100vh - 100px);
`;

const CloseIcon = styled.i`
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 1.2rem;
  cursor: pointer;
`;
