import React from 'react';
import {ButtonOrange} from "../../styles/Buttons";
import {StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";
import {T} from "../../styles/Theme";
import Fade from 'react-reveal/Fade';

const DivisionOne = () => {
    return (
        <SlideStyled>
            <div className="content">
                <Fade bottom>
                    <p>
                        <span>Desde el año 2006 </span> implementando sistemas de control de calidad en diferentes especies de fruta fresca.
                    </p>
                </Fade>

            </div>

            <StaticImage
                className="backgroundImage"
                src="../../images/division-1.png"
                alt={""}
            />
        </SlideStyled>
    );
};

export default DivisionOne;


const SlideStyled = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  
  .backgroundImage {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
  }
  
  .content {
    color: white;
    z-index: 10;
    max-width: ${T.maxWidth};
    width: 100%;
    margin: 0 auto;
    
    p {
      font-size: 20px;
      max-width: 600px;
      width: 90%;
      text-align: center;
      margin: auto;
      
      @media (min-width: 550px) {
        font-size: 26px;
      }
      
      span {
        color: ${T.orange};
      }
    }
  }
`;