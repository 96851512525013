export const testimonials = [
    {
        title: "Nos garantiza calidad en nuestro trabajo",
        text: "\"El servicio brindado por Inglobo a través del sistema de control de calidad, en conjunto con nuestras" +
            " definiciones de estándares de calidad nos ha permitido tener resultados positivos en lo que" +
            " significa nuestro trabajo. Así mismo el soporte brindado es oportuno y confiable, ha sido una" +
            " herramienta de trabajo que nos ha permitido lograr una sólida base de datos, la cual podemos" +
            " consultar en cualquier momento, generar reportes y distribuirlos en forma rápida y clara para" +
            " orientación del trabajo de productores, agrónomos, comerciales, entre otros. El utilizar este sistema" +
            " nos garantiza calidad en nuestro trabajo, la página es fácil de manejar y de visualizar, amigable y" +
            " contiene una amplia gama de información. Desde que comenzamos a usar este sistema, hemos dado un" +
            " gran paso tecnológico, nos ha permitido ser más eficientes y entregar informes de una óptima calidad\".",
        author: "Soledad Gallegos, Jefa de Calidad",
        location: "Agricom, Chile"
    },
    {
        title: "Información de manera rápida y clara",
        text: "\"El Sistema Inglobo dio el soporte a Agricola San José para dar el siguiente paso, la aplicación" +
            " de una herramienta tecnológica para sistematizar el Control de Calidad en nuestra planta de empaque." +
            " El manejo de información es de gran importancia en el sector agroindustrial, gracias al sistema podemos" +
            " manejar esa información de manera rápida y clara dándonos la capacidad de tomar decisiones en el momento" +
            " justo, así como agilizar el proceso de inspecciones de calidad en la post cosecha de uva de mesa en" +
            " nuestro Packing con un sistema práctico de ingreso de datos y permitiéndonos analizar los datos al" +
            " instante. Además de eso nos gusta trabajar con Inglobo por el soporte técnico permanente que nos" +
            " ofrecen\".",
        author: "Annie Burga, Jefa de Calidad Packing y Postcosecha",
        location: "Perú"
    },
    {
        title: "Herramienta integral",
        text: "\"El desarrollo tecnológico de Inglobo ha sido una herramienta que nos ha brindado soporte a la" +
            " información del resultado de nuestros procesos en forma dinámica, clara y oportuna. Gracias a este" +
            " sistema hemos podido monitorear y controlar la calidad de nuestros productos mejorar la consistencia" +
            " en cada una de nuestras categorías. Además, nos ha permitido incorporar nuevas etapas de control en la" +
            " cadena productiva lo que convierte el sistema en una herramienta integral. Estamos muy satisfechos con" +
            " este servicio y espero podamos continuar con la misma sinergia\".",
        author: "Aldo Millar, Jefe de Calidad",
        location: "Geofrut, Chile"
    },
    {
        title: "Herramienta de fácil acceso",
        text: "\"Este es nuestro tercer año con Inglobo y estoy muy contenta por sus servicios, cada año hemos" +
            " ido mejorando para cumplir con las necesidades de los productores, es una herramienta de fácil acceso" +
            " y que permite mantener a todos informados en línea, es decir una información rápida y oportuna." +
            " Agradezco a Paula Tobar por su profesionalismo y disposición para atender cada requerimiento y" +
            " dar solución en el minuto que se necesita\".",
        author: "Leandra Carrasco, Asistente de Calidad",
        location: "Rio Blanco, Chile"
    },

]