import React from 'react';
import styled from "styled-components";
import {T} from "../../styles/Theme"
import {testimonials} from "./data-testimonials"
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import {Pagination} from "swiper";

const Testimonials = () => {
    return (
        <TestStyled>
            <h2 className="title-section">TESTIMONIOS DE CLIENTES</h2>
            <SwiperStyled
                pagination={true}
                modules={[Pagination]}
                autoplay={{
                    delay: 4500,
                    disableOnInteraction: false,
                }}
            >

                {
                    testimonials.map((el,i) => {
                        return (
                            <SwiperSlide key={i} className={"slide"}>
                                <CardContainer>
                                    <p className={"title"}><strong>{el.title}</strong></p>
                                    <p className={"text"}><i>{el.text}</i></p>
                                    <p className={"author"}><strong>{el.author}</strong></p>
                                    <p className={"location"}>{el.location}</p>
                                </CardContainer>
                            </SwiperSlide>
                        )
                    })
                }

            </SwiperStyled>
        </TestStyled>
    );
};

export default Testimonials;

const CardContainer = styled.div`
  color: white;
  text-align: center;
  padding: 30px;
  line-height: 2;
  max-width: 700px;
  margin: auto;
  
  .title, .text {
    font-size: 14px;
  }
  
  .author, .location {
    font-size: 12px;
  }
  
`;

const SwiperStyled = styled(Swiper)`
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: transparent;
    border: 1px solid ${T.orange};
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background-color: ${T.orange};
    width: 8px;
    height: 8px;
  }
`;

const TestStyled = styled.div`
  //min-height: 500px;
  width: 100%;
  background-color: ${T.gray};
  padding: 30px 0;
  
  .title-section {
    color: white;
  }
  
  .container {
    max-width: ${T.maxWidth};
  }
`;