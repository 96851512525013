import React from 'react';
import {StaticImage} from "gatsby-plugin-image";
import styled from "styled-components";
import {T} from "../../../styles/Theme"
import Fade from 'react-reveal/Fade';

const Fourth = () => {
    return (
        <FourthStyled>
            <Fade bottom>
                <StaticImage
                    alt=""
                    className="image"
                    src="../../../images/ipad2image.png"
                />

                <div className="content">
                    <h3>
                        <strong>Inglobo Chile</strong> se encuentra entre las
                        tres empresas más grandes de <span>control
                    de calidad de fruta</span> de exportación de Chile.
                    </h3>

                    <p>
                        Hace 10 años, incorporamos la tecnología en nuestros servicios internos de
                        control de calidad, creando, implementando y utilizando distintos softwares para
                        optimizar y hacer más eficientes los procesos de inspección y cumplimientos de
                        normativas exigidas en el proceso de exportación.
                    </p>
                </div>
            </Fade>

        </FourthStyled>
    );
};

export default Fourth;

const FourthStyled = styled.section`
  max-width: ${T.maxWidth};
  margin: auto;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .content {
    max-width: 550px;
  }
  
  h3 {
    font-size: 20px;
    span {
      color: ${T.orange};
    }
  }
  
  p {
    line-height: 2.2;
    margin: 0;
  }
  
  @media (min-width: 650px) {
    padding: 100px 20px;
    flex-direction: row;
    
    h3 {
      font-size: 25px;
      padding-left: 40px;
    }

    p {
      padding-left: 40px;
    }
  }
  
  .image {
    min-width: 300px;
  }
`;